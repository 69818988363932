import axios from 'axios'
import { getBearerToken } from './getUsername'
import fileDownload from 'js-file-download'

const getToken = async () => {
	const token = await getBearerToken()
	return token
}

const https = axios.create({
	baseURL: process.env.REACT_APP_URL_API,
	headers: {
		mode: 'no-cors',
		credentials: 'same-origin',
		'Access-Control-Allow-Origin': '*',
		apikey: process.env.REACT_APP_API_KEY || '',
	},
})

const fetchGET2 = async (endpoint: string, configOverride: object | null = null) => {
	const token = await getToken()

	let config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	if (configOverride !== null) {
		config = { ...config, ...configOverride }
	}

	// @ts-ignore
	const response = await https.get(endpoint, config)
	return response
}

const fetchGETBlob2 = async (endpoint: string, configOverride: object | null = null, fileName: string) => {
	const token = await getToken()

	let config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob',
	}

	if (configOverride !== null) {
		config = { ...config, ...configOverride }
	}

	// @ts-ignore
	const response = await https.get(endpoint, config)
	return fileDownload(response.data, fileName)
}

const fetchPOST2 = async (endpoint: string, data: FormData) => {
	const token = await getToken()
	let config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await https.post(endpoint, data, config)
	return response
}

const fetchPATCH2 = async (endpoint: string, data: FormData) => {
	const token = await getToken()
	let config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await https.patch(endpoint, data, config)
	return response
}

const fetchDELETE2 = async (endpoint: string, data: FormData) => {
	const token = await getToken()
	let config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
	}

	const response = await https.delete(endpoint, config)
	return response
}

export { fetchGET2, fetchGETBlob2, fetchPOST2, fetchPATCH2, fetchDELETE2 }
